import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"

const AboutPage = () => (
  <Layout>
    <SEO title="Confirm Your Email For Chicken Yard" noindex />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0" />
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <h1 className="text-center">Confirm Your Email For Chicken Yard</h1>
            <ShortLine />

            <p>
              <strong>
                We've got your email address but you need to confirm it first!
              </strong>
            </p>
            <p>
              Any moment now you'll receive an email with a link that you need
              to click on to confirm that you want to receive emails from us.
            </p>
            <p>Once you do that you're signed up and ready to go.</p>
            <p>
              <strong>
                In the meantime, make sure to Like us on{" "}
                <a
                  href="https://www.facebook.com/mychickenyard"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
                .
              </strong>
            </p>
            <p>Thank you very much.</p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const AboutContent = styled.div`
  max-width: 665px;
  margin: auto;
  padding-bottom: ;
`
